import React from 'react'
import PropTypes from 'prop-types'
import { GlobalStyles } from 'twin.macro'

const Layout = ({ children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div {...rest} tw="bg-indigo-50 dark:bg-blue-1000 bg-opacity-100 dark:text-white text-gray-800">
    <GlobalStyles />
    {children}
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

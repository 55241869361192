import React from 'react'
import PropTypes from 'prop-types'
import 'twin.macro'
import HiddenMessage from './HiddenMessage'

const Header = ({ back, hidden, children }) => (
  // eslint-disable-next-line max-len
  <div tw="mb-3 mx-8 md:mx-0">
    {back && (
      <a href={back}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          tw="h-5 w-5 mr-2 inline text-blue-700 dark:text-blue-50"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </a>
    )}
    <h1
      tw="inline-block align-middle font-bold text-2xl from-blue-700 dark:from-blue-50
      bg-clip-text bg-gradient-to-br to-blue-900 dark:to-blue-500 text-transparent tracking-wide"
    >
      {children}
    </h1>
    {hidden && (
      <div>
        <HiddenMessage />
      </div>
    )}
  </div>
)

Header.propTypes = {
  children: PropTypes.string.isRequired,
  back: PropTypes.string,
  hidden: PropTypes.bool,
}

Header.defaultProps = {
  back: null,
  hidden: false,
}

export default Header

import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

const Head = ({ title }) => (
  <Helmet
    defaultTitle="Hobbyspoor.nl - Een verslag van een N-Spoor modelspoorbaan"
    title={title}
    titleTemplate="%s | Hobbyspoor.nl"
  >
    <script async defer data-domain="hobbyspoor.nl" src="https://plausible.io/js/plausible.js" />
  </Helmet>
)

Head.propTypes = {
  title: PropTypes.string,
}

Head.defaultProps = {
  title: null,
}

export default Head

import React from 'react'
import PropTypes from 'prop-types'
import 'twin.macro'
import Logo from './Logo'
import Footer from './Footer'

const Container = ({ children }) => (
  <div tw="md:w-3/4 lg:w-3/5 2xl:w-1/2 w-screen mx-auto md:px-10 py-20 min-h-screen">
    <div tw="mb-10">
      <Logo>Hobbyspoor</Logo>
    </div>
    {children}
    <Footer />
  </div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container

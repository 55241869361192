import React from 'react'
import 'twin.macro'

const Footer = () => (
  <div tw="text-sm text-gray-400 dark:text-gray-600 mx-8 md:mx-auto mt-20 max-w-screen-md">
    <div tw="mb-1">
      Deze website is gemaakt door
      {' '}
      <a href="https://patrick.vanmarsbergen.com">Patrick van Marsbergen</a>
    </div>
    <a
      href="https://www.instagram.com/hobbyspoor/"
      tw="text-indigo-400 dark:opacity-75 hover:text-indigo-600 mr-5 last:mr-0 block md:inline-block"
      rel="nofollow"
    >
      Instagram
    </a>
    <a
      href="https://plausible.io/hobbyspoor.nl/"
      tw="text-indigo-400 dark:opacity-75 hover:text-indigo-600 mr-5 last:mr-0 block md:inline-block"
      target="_blank"
      rel="nofollow noreferrer"
    >
      Website Stats
    </a>
    <a
      href="https://tikkie.me/pay/vslouk3m8ghkkoi4dsg0"
      tw="text-indigo-400 dark:opacity-75 hover:text-indigo-600 mr-5 last:mr-0 block md:inline-block"
      target="_blank"
      rel="nofollow noreferrer"
    >
      Doneer via Tikkie!
    </a>
    <a
      href="https://www.paypal.com/donate?business=L9KPLYLDXWWY8&currency_code=EUR"
      tw="text-indigo-400 dark:opacity-75 hover:text-indigo-600 mr-5 last:mr-0 block md:inline-block"
      target="_blank"
      rel="nofollow noreferrer"
    >
      Doneer via Paypal!
    </a>
  </div>
)

export default Footer

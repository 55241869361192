import React from 'react'
import tw from 'twin.macro'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'

const BlogPostBody = ({ children }) => (
  <div
    css={css`
      p {
        ${tw`leading-relaxed text-gray-800 dark:text-gray-300 mx-8 md:mx-0`}
        &:not(:last-child) {
          ${tw`mb-4`}
        }
      }

      a {
        ${tw`text-blue-900 dark:text-blue-200`}
        &:hover,
        &:hover:active {
          ${tw`opacity-75`}
        }
      }

      h1, h2, h3, h4, h5, h6 {
        ${tw`font-bold text-xl mb-1 from-blue-700 dark:from-blue-50 bg-clip-text bg-gradient-to-br to-blue-900
                  dark:to-blue-500 text-transparent tracking-wide mx-8 md:mx-0`}
      }

      blockquote {
        ${tw`p-5 border-l-8 border-blue-800 my-5 lg:mx-5 2xl:mx-10 bg-indigo-100 dark:bg-blue-900`}
        p, h1, h2, h3, h4, h5, h6 {
          ${tw`leading-relaxed mx-3`}
        }
      }

      img {
        ${tw`lg:px-5 2xl:px-10 ml--8 md:ml-0`}

        @media (max-width: 768px) {
            max-width: calc(100% + 4rem);
        }

        & + em {
          ${tw`not-italic md:px-10 text-sm md:ml-3 text-center block mt-2 lg:text-left text-gray-500
                    dark:text-blue-100 dark:opacity-75 italic`}
        }
      }
    `}
    dangerouslySetInnerHTML={{ __html: children }} /* eslint-disable-line react/no-danger */
  />
)

BlogPostBody.propTypes = {
  children: PropTypes.string.isRequired,
}

export default BlogPostBody

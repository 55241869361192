import React from 'react'
import PropTypes from 'prop-types'
import 'twin.macro'

const Logo = ({ children }) => (
  <a href="/">
    <h1
      /* eslint-disable-next-line max-len */
      tw="font-bold lowercase from-blue-500 dark:from-blue-50 bg-clip-text bg-gradient-to-br to-blue-900 dark:to-blue-500 text-2xl text-center py-1 text-transparent px-2 border-b-8 tracking-wider border-t-8 border-blue-800 dark:border-blue-500 w-max mx-auto"
    >
      {children}
    </h1>
  </a>
)

Logo.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Logo
